<style lang="scss">
.form-control.noarrow {
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>

<template>
  <Modal ref="modal">
    <template #headerTitle
      >{{ lp.contactid ? "Modification" : "Définition" }} du Locataire
      principal</template
    >
    <form id="locaprinc_form" @submit.stop.prevent="submit">
      <div class="row row-cols-1 row-cols-sm-2 g-3">
        <div class="col">
          <label for="firstname" class="form-label required">Prénom</label>
          <input
            type="text"
            class="form-control"
            id="firstname"
            v-model="lp.firstname"
            required
          />
        </div>
        <div class="col">
          <label for="lastname" class="form-label required">Nom</label>
          <input
            type="text"
            class="form-control"
            id="lastname"
            v-model="lp.lastname"
            required
          />
        </div>
        <div class="col">
          <label for="civilite" class="form-label required">Civilité</label>
          <select
            class="form-select"
            id="civilite"
            v-model="lp.qua_civilite"
            required
          >
            <option v-for="opt in lists.civilite" :value="opt.id" :key="opt.id">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="datebail" class="form-label">Date du bail en cours</label>
          <input
            type="date"
            class="form-control"
            id="datebail"
            v-model="lp.new_datedupremierbaildulocataire"
          />
        </div>
        <div class="col">
          <label for="birthdate" class="form-label">Date de naissance</label>
          <input
            type="date"
            class="form-control"
            id="birthdate"
            v-model="lp.birthdate"
          />
        </div>
        <div class="col">
          <label for="sitfam" class="form-label">Situation familiale</label>
          <select
            class="form-select"
            id="sitfam"
            v-model="lp.qua_situationfamiliale"
          >
            <option v-for="opt in lists.sitfam" :value="opt.id" :key="opt.id">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="person" class="form-label">Personnes à charge</label>
          <input
            type="number"
            class="form-control text-end noarrow"
            id="person"
            v-model="lp.qua_nombredepersonnesacharge"
          />
        </div>
        <div class="col">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="lp.emailaddress1"
          />
        </div>
        <div class="col">
          <label for="mobile" class="form-label">Mobile</label>
          <input
            type="text"
            class="form-control"
            id="mobile"
            v-model="lp.mobilephone"
          />
        </div>
        <div class="col">
          <label for="phone" class="form-label">Fixe</label>
          <input
            type="text"
            class="form-control"
            id="phone"
            v-model="lp.telephone1"
          />
        </div>
        <div class="col">
          <label class="form-label">Contentieux en cours</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="content_yes"
                :value="true"
                v-model="lp.qua_contentieuxencours"
              />
              <label class="form-check-label" for="content_yes">Oui</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="content_no"
                :value="false"
                v-model="lp.qua_contentieuxencours"
              />
              <label class="form-check-label" for="content_no">Non</label>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="content_amount" class="form-label"
            >Montant du contentieux</label
          >
          <div class="d-flex align-items-center">
            <input
              type="number"
              step=".1"
              class="form-control text-end noarrow"
              id="content_amount"
              v-model="lp.qua_montantcontentieux"
            />
            <span class="ms-1">&euro;</span>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <div class="spinner-border text-info me-auto" v-if="loading">
        <span class="visually-hidden">Chargement...</span>
      </div>
      <button type="button" class="btn btn-secondary" @click="close">
        Annuler
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        form="locaprinc_form"
        :disabled="loading"
      >
        Valider
      </button>
    </template>
  </Modal>
</template>

<script src="./BienImmoLocaprincModal.ts" />
