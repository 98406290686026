<style lang="scss">
.form-control.noarrow {
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>

<template>
  <Modal ref="modal">
    <template #headerTitle>{{
      vacant ? "Déclaration de vacant" : "Modification du bien immobilier"
    }}</template>
    <form id="bienimmo_form" @submit.stop.prevent="submit">
      <div class="row row-cols-1 row-cols-sm-2 g-3">
        <div class="col" v-if="'qua_etat' in bi">
          <label for="etat" class="form-label required">État</label>
          <select class="form-select" id="etat" v-model="bi.qua_etat" required>
            <option v-for="opt in lists.etat" :value="opt.id" :key="opt.id">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="col" v-if="'qua_name' in bi">
          <label for="name" class="form-label required"
            >&#8470; de gestion</label
          >
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="bi.qua_name"
            required
          />
        </div>
        <div class="col">
          <label
            for="nature"
            :class="['form-label', { required: natures.length }]"
            >Nature</label
          >
          <select
            class="form-select"
            id="nature"
            v-model="bi.qua_nature"
            :required="natures.length"
            :disabled="!natures.length"
            @input="selectNature"
          >
            <option v-for="opt in natures" :value="opt.id" :key="opt.id">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="col">
          <label
            for="typologie"
            :class="['form-label', { required: typologies.length }]"
            >Typologie</label
          >
          <select
            class="form-select"
            id="typologie"
            v-model="bi.qua_typologie"
            :required="typologies.length"
            :disabled="!typologies.length"
          >
            <option v-for="opt in typologies" :value="opt.id" :key="opt.id">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="etage" class="form-label required">Étage</label>
          <select
            class="form-select"
            id="etage"
            v-model="bi.qua_etage"
            required
          >
            <option v-for="opt in lists.etage" :value="opt.id" :key="opt.id">
              {{ opt.label }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="surface" class="form-label required"
            >Surface habitable</label
          >
          <input
            type="number"
            step=".01"
            class="form-control text-end noarrow"
            id="surface"
            v-model="bi.qua_surfacehabitable"
            required
          />
        </div>
        <div class="col">
          <label for="carrez" class="form-label">Carrez</label>
          <input
            type="number"
            step=".01"
            class="form-control text-end noarrow"
            id="carrez"
            v-model="bi.qua_loicarrez"
          />
        </div>
        <div class="col">
          <label class="form-label required">Copropriété</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="copro_yes"
                :value="true"
                name="qua_copropriete_boolean"
                v-model="bi.qua_copropriete_boolean"
                required
              />
              <label class="form-check-label" for="copro_yes">Oui</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="copro_no"
                :value="false"
                name="qua_copropriete_boolean"
                v-model="bi.qua_copropriete_boolean"
              />
              <label class="form-check-label" for="copro_no">Non</label>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="charge" class="form-label">Charges annuelles copro.</label>
          <div class="d-flex align-items-center">
            <input
              type="number"
              step=".01"
              class="form-control text-end noarrow"
              id="charge"
              v-model="bi.qua_chargesannuellescopropriete"
            />
            <span class="ms-1">&euro;</span>
          </div>
        </div>
        <div class="col">
          <label class="form-label">DPE en cours</label>
          <div class="mt-1">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="dpe_yes"
                :value="true"
                v-model="bi.qua_isdpeencours"
                name="qua_isdpeencours"
              />
              <label class="form-check-label" for="dpe_yes">Oui</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="dpe_no"
                :value="false"
                v-model="bi.qua_isdpeencours"
                name="qua_isdpeencours"
              />
              <label class="form-check-label" for="dpe_no">Non</label>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="dpe" class="form-label">Valeur du DPE</label>
          <input
            type="number"
            class="form-control text-end noarrow"
            id="dpe"
            min="0"
            max="999"
            v-model="bi.qua_valeurdudpe"
          />
        </div>
        <div class="col" v-if="'qua_nomdanstatlocatif' in bi">
          <label for="ancienloc" class="form-label"
            >Nom dans état locatif</label
          >
          <input
            type="text"
            class="form-control"
            id="ancienloc"
            v-model="bi.qua_nomdanstatlocatif"
          />
        </div>
        <div class="col" v-if="'qua_datedesortiedulocataire' in bi">
          <label for="datesortie" class="form-label required">Date de sortie</label>
          <input
            type="date"
            class="form-control"
            id="datesortie"
            v-model="bi.qua_datedesortiedulocataire"
            required
          />
        </div>
      </div>
    </form>
    <template #footer>
      <div class="spinner-border text-info me-auto" v-if="loading">
        <span class="visually-hidden">Chargement...</span>
      </div>
      <button type="button" class="btn btn-secondary" @click="close">
        Annuler
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        form="bienimmo_form"
        :disabled="loading"
      >
        {{ vacant ? "Déclarer vacant" : "Valider" }}
      </button>
    </template>
  </Modal>
</template>

<script src="./BienImmoUpdateModal.ts" />
