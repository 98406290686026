import { mapGetters } from "vuex";
import Modal from "@/views/common/Modal.vue";
import * as Constants from '@/utils/crm/crm-constants';
import { getBIDependencies } from "@/utils/crm/crm";

export default {
    props: ["bienimmo", "vacant"],
    components: {
        Modal,
    },
    data() {
        return {
            bi: null,
            lists: {
                etat: Constants.BI_ETAT,
                nature: Constants.BI_NATURE,
                typologie: Constants.BI_TYPOLOGIE,
                etage: Constants.BI_ETAGE,
            },
        };
    },
    computed: {
        ...mapGetters({
            loading: "loading/loading",
        }),
        natures() {
            if (!this.bi) return [];
            const allowedIds = getBIDependencies('qua_destination', 'qua_nature', this.bi.qua_destination);
            return this.lists.nature.filter(nature => allowedIds.includes(nature.id));
        },
        typologies() {
            if (!this.bi) return [];
            const allowedIds = getBIDependencies('qua_nature', 'qua_typologie', this.bi.qua_nature);
            return this.lists.typologie.filter(typologie => allowedIds.includes(typologie.id));
        },
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        selectNature() {
            this.bi.qua_typologie = null;
        },
        submit() {
            const payload = {
                id: this.bi.qua_bienimmobilierid,
                data: this.bi,
            };
            this.$store.dispatch('bienimmo/put' + (this.vacant ? 'Vacant' : ''), payload).then(response => {
                this.$notify({ type: 'success', text: 'Le bien immobilier a été mis à jour avec succès !' });
                this.$emit('submit');
                this.close();
            });
        },
    },
    mounted() {
        const writableProps = [
            'qua_bienimmobilierid',
            'qua_destination',
            'qua_nature',
            'qua_typologie',
            'qua_etage',
            'qua_surfacehabitable',
            'qua_loicarrez',
            'qua_copropriete_boolean',
            'qua_chargesmensuellesdecopropriete',
            'qua_chargesannuellescopropriete',
            'qua_isdpeencours',
            'qua_valeurdudpe',
        ];
        if (this.vacant) {
            writableProps.push(
                'qua_name',
                'qua_nomdanstatlocatif',
                'qua_datedesortiedulocataire',
            );
        } else {
            writableProps.push(
                'qua_etat',
            );
        }
        this.bi = writableProps.reduce((obj2, key) => {
            obj2[key] = this.bienimmo[key];
            return obj2;
        }, {});
    }
};
