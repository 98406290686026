import { mapGetters } from "vuex";
import dayjs from "dayjs";
import Modal from "@/views/common/Modal.vue";
import * as Constants from '@/utils/crm/crm-constants';

export default {
    props: ["locaprinc", "bienimmoId"],
    components: {
        Modal,
    },
    data() {
        return {
            lp: null,
            lists: {
                civilite: Constants.LP_CIVILITE,
                sitfam: Constants.LP_SITFAM,
            },
        };
    },
    computed: {
        ...mapGetters({
            loading: "loading/loading",
        }),
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        submit() {
            const payload = {
                id: this.bienimmoId,
                data: this.lp,
            };
            this.$store.dispatch('bienimmo/putLocaprinc', payload).then(response => {
                this.$notify({ type: 'success', text: 'Le locataire principal a été mis à jour avec succès !' });
                this.$emit('submit');
                this.close();
            });
        },
    },
    mounted() {
        this.lp = [
            'contactid',
            'qua_civilite',
            'firstname',
            'lastname',
            'new_datedupremierbaildulocataire',
            'mobilephone',
            'telephone1',
            'emailaddress1',
            'birthdate',
            'qua_situationfamiliale',
            'qua_nombredepersonnesacharge',
            'qua_contentieuxencours',
            'qua_montantcontentieux',
        ].reduce((obj2, key) => {
            obj2[key] = this.locaprinc[key];
            return obj2;
        }, {});
        ['new_datedupremierbaildulocataire', 'birthdate'].forEach(dateField => {
            if (this.lp[dateField]) {
                this.lp[dateField] = dayjs(this.lp[dateField]).format('YYYY-MM-DD');
            }
        });
    }
};
