import { mapGetters } from 'vuex';
import dayjs from "dayjs";
import * as Constants from '@/utils/crm/crm-constants';
import { moneyFormat } from '@/utils/display';
import ProgressPills from "@/views/common/ProgressPills.vue";
import BienImmoDossier from "./BienImmoDossier.vue";
import UpdateModal from "./form/BienImmoUpdateModal.vue";
import LocaprincModal from "./form/BienImmoLocaprincModal.vue";
import ProgramInterlos from "@/views/program/ProgramInterlos.vue";
import { extrudeAliasedFields } from '@/utils/crm/crm';
import { dateFormatter } from '@/utils/crm-mixin';


const defaultData = () => ({
    bienimmo: {},
    locaprinc: {},
    dossiers: [],
    prices: [],
    interlos: {},
    expandedDossier: 0,
    bienimmoToUpdate: null,
    locaprincToUpdate: null,
    vacantMode: false,
    conclusion: {},
    scorings: Constants.LP_SCORING,
});


export default {
    props: ["id"],
    components: {
        BienImmoDossier,
        LocaprincModal,
        ProgramInterlos,
        ProgressPills,
        UpdateModal,
    },
    mixins: [ dateFormatter ],
    data: defaultData,
    computed: {
        ...mapGetters({
            loading: 'loading/loading',
        }),
        etats() {
            if (!this.bienimmo) return [];
            const etats = Constants.BI_ETATS_SHORT.map(etat => Object.assign({}, etat));
            if (this.etatIsBloque) {
                etats[0].label = 'Bloqué';
            }
            return etats;
        },
        etat() {
            return this.bienimmo.qua_etat;
        },
        etatId() {
            if (!this.bienimmo) return null;
            const etatGuid = this.bienimmo.qua_etat;
            if (this.etatIsBloque || this.etatIsCessible) return 0;
            if (etatGuid === Constants.BI_ETAT_VENTEENC) return 1;
            return 2;
        },
        etatIsVente() {
            const etatGuid = this.bienimmo.qua_etat;
            return etatGuid === Constants.BI_ETAT_VENTEENC
                || etatGuid === Constants.BI_ETAT_VENDU
                || etatGuid === Constants.BI_ETAT_VENDUHQT
                ;
        },
        etatIsCessible() {
            return this.bienimmo.qua_etat === Constants.BI_ETAT_CESSIBLE;
        },
        etatIsBloque() {
            return !this.etatIsCessible && !this.etatIsVente;
        },
        bail2Years() {
            const bail = this.locaprinc.new_datedupremierbaildulocataire;
            if (!bail) return 0
            return dayjs(bail).isBefore(dayjs().subtract(2, 'year')) ? 1 : -1;
        },
        isOccupe() {
            return this.bienimmo && this.bienimmo.qua_occupevacant;
        },
        hasLocaPrinc() {
            return !!this.locaprinc.contactid;
        },
        ensemble() {
            return extrudeAliasedFields(this.bienimmo, 'ensemble.');
        },
        chronos() {},
        userIsMaster() {
            if (!this.bienimmo || !this.ensemble.qua_mandant) return false;
            const role = this.$store.getters['common/mandants'].find(mandant => mandant.accountid === this.ensemble.qua_mandant);
            return role.mso_roleoption === Constants.CT_ROLE_MASTER;
        },
        dossierNotaire() {
            if (!this.dossiers.length) return null;
            return extrudeAliasedFields(this.dossiers[0], 'account');
        },
    },
    methods: {
        moneyFormat(amount) {
            return moneyFormat(amount);
        },
        openBIModal(vacant = false) {
            this.bienimmoToUpdate = this.bienimmo;
            this.vacantMode = vacant === true;
        },
        closeBIModal() {
            this.bienimmoToUpdate = null;
            this.vacantMode = false;
        },
        openLPModal() {
            this.locaprincToUpdate = this.hasLocaPrinc ? this.locaprinc : {};
        },
        closeLPModal() {
            this.locaprincToUpdate = null;
        },
        loadBI() {
            return this.$store.dispatch("bienimmo/getById", this.id).then((bienimmo) => {
                // console.log(bienimmo);
                this.bienimmo = bienimmo;
                this.prices = bienimmo.prices;
            });
        },
        loadLP() {
            this.$store.dispatch("bienimmo/getLocaprinc", this.id).then((locaprinc) => {
                // console.log(locaprinc);
                this.locaprinc = locaprinc;
            });
        },
        load() {
            Object.assign(this.$data, defaultData());
            this.$store.dispatch("bienimmo/getEntretiens", this.id).then((entretiens) => {
                const entretienWithConclusion = entretiens.find(entretien => entretien.qua_conclusionentretienlocataire);
                if (!entretienWithConclusion) return;
                this.conclusion = entretienWithConclusion.qua_conclusionentretienlocataire;
            });
            this.$store.dispatch("bienimmo/getDossiers", this.id).then((dossiers) => {
                this.dossiers = dossiers;
            });
            this.loadLP();
            this.loadBI().then(() => {
                this.$store.dispatch("program/getInterlos", this.bienimmo.qua_programmedeventeid).then((interlos) => {
                    this.interlos = interlos;
                });
            });
        },
    },
    watch: {
        id() {
            this.load();
        }
    },
    mounted() {
        this.load();
    },
};
