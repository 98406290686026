<template>
  <section :class="['dossier', { disabled }]">
    <div class="dv_header card-body">
      <span class="dv_icon icon">
        <fa :icon="disabled ? 'ban' : 'crown'" />
      </span>
      <span class="dv_id d-none d-sm-inline">{{ dossier.qua_name }}</span>
      <span class="dv_date d-none d-lg-inline"
        >Créé le {{ df(dossier.createdon) }}</span
      >
      <span class="dv_status">{{ dossier.statuscode_label }}</span>
      <span class="dv_amount">{{
        moneyFormat(dossier.qua_prixdeventetotal)
      }}</span>
      <span class="dv_expand icon">
        <fa icon="chevron-right" />
      </span>
    </div>
    <div class="dv_body card-body py-0">
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 my-3">
        <div class="col d-sm-none">
          <span class="fw-light">Dossier : </span>
          <span>{{ dossier.qua_name }}</span>
        </div>
        <div class="col d-lg-none">
          <span class="fw-light">Créé le : </span>
          <span>{{ df(dossier.createdon) }}</span>
        </div>
        <div class="col">
          <span class="fw-light">Composition familiale : </span>
          <span>{{ dossier.qua_compositionfamiliale }}</span>
        </div>
        <div class="col">
          <span class="fw-light">Revenu Fiscal de Réf. : </span>
          <span>{{
            moneyFormat(dossier.qua_revenuefiscalreferencefoyermoins2)
          }}</span>
        </div>
        <div class="col">
          <span class="fw-light">Année de Référence : </span>
          <span>{{ dossier.mso_rfryear }}</span>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-5">
          <h5 class="text-info">Chronologie</h5>
          <table>
            <tbody>
              <tr v-for="date in dates" :key="date">
                <td class="text-end">{{ date.date }}</td>
                <td class="fw-light ps-2">{{ date.label }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-7">
          <h5 class="text-info">Acquéreurs</h5>
          <table class="w-100 mb-3">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Âge</th>
                <th>Statut juridique</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="acque in acquereurs" :key="acque">
                <td>{{ acque.contact_fullname }}</td>
                <td>{{ acque.contact_age }}</td>
                <td>{{ acque.qua_statutjuridique_label }}</td>
              </tr>
              <tr v-if="!dossier?.qua_acquereurs?.length">
                <td colspan="3" class="text-center text-muted">Aucun</td>
              </tr>
            </tbody>
          </table>
          <h5 class="text-info">Autres biens acquis</h5>
          <table class="w-100">
            <thead>
              <tr>
                <th>&#8470; de gestion</th>
                <th>Destination</th>
                <th>Nature</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="acquis in biAcquis" :key="acquis">
                <td>{{ acquis.bienimmobilier_name }}</td>
                <td>{{ acquis.bienimmobilier_destination_label }}</td>
                <td>{{ acquis.bienimmobilier_nature_label }}</td>
              </tr>
              <tr v-if="!dossier?.autres_biens_acquis?.length">
                <td colspan="3" class="text-center text-muted">Aucun</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { moneyFormat } from "@/utils/display";
import { dateFormatter } from '@/utils/crm-mixin';

export default {
  props: ["dossier", "bienimmoId"],
  mixins: [ dateFormatter ],
  computed: {
    disabled() {
      return this.dossier.statecode !== 0 && !this.dossier.qua_datesignatureacteauthentique;
    },
    dates() {
      return [
        { date: this.df(this.dossier.qua_datedannulation), label: "Date d’annulation" },
        { date: this.df(this.dossier.qua_datesignatureacteauthentique), label: "Signature acte authentique" },
        { date: this.df(this.dossier.qua_datesignaturedelavantcontrat), label: "Signature avant contrat" },
        { date: this.df(this.dossier.qua_datetransmissionnotaire), label: "Transmission notaire" },
        { date: this.df(this.dossier.qua_datedefindudelaisru), label: "Fin du délai de rétractation" },
        { date: this.df(this.dossier.qua_datedenvoiavantcontrat || this.dossier.qua_dateenvoisru), label: "Envoi SRU" },
      ];
    },
    acquereurs() {
      const acquereurs = this.dossier.qua_acquereurs;
      const statuts = this.dossier.qua_statutjuridiqueacquereurs;
      // Dedoublon
      const acqIds = [...new Set(acquereurs.map(acq => acq.qua_acquereurid))];
      // Finding the statut juridique
      const acqs = acqIds.map(acqId => {
        const acq = acquereurs.find(acq => acq.qua_acquereurid === acqId);
        acq.qua_statutjuridique_label = statuts.find(statut => statut.qua_acquereurid === acq.qua_acquereurid).qua_statutjuridique_label;
        return acq;
      });
      return acqs;
    },
    biAcquis() {
      return this.dossier.autres_biens_acquis.filter(acquis => acquis.bienimmobilier_id !== this.bienimmoId);
    },
  },
  methods: {
    moneyFormat(amount) {
      return moneyFormat(amount);
    },
  },
};
</script>
