<style lang="scss">
@import "./BienImmo";
</style>

<template>
  <div id="bienimmo">
    <Header>
      <span
        >{{ bienimmo.qua_nature_label }} &bull;
        {{ bienimmo.qua_typologie_label }}</span
      >
      <small class="fw-light ms-2">Réf. {{ bienimmo.qua_name }}</small>
      <template #entity>Bien Immobilier</template>
    </Header>
    <div class="supercard p-3 p-md-4">
      <div class="d-none d-lg-flex mb-5">
        <ProgressPills :pills="etats" :pillId="etatId" />
      </div>
      <div class="d-lg-none mb-3">
        <span class="fw-light">État :</span>
        {{ etats[etatId].label }}
      </div>
      <h5 class="text-info mb-3">Caractéristiques</h5>
      <div class="d-flex flex-wrap mb-3">
        <div class="flex-grow-1">
          <div>
            <fa icon="map-marker" class="fw-light me-1" />
            {{ ensemble.qua_adresse1 }},
            {{ ensemble.qua_adresse2 }}
            {{ ensemble.qua_commune_label }}
            {{ ensemble.qua_ville }}
          </div>
        </div>
        <div class="text-truncate">
          {{ bienimmo.qua_occupevacant_label }} &bull;
          {{ bienimmo.qua_etat_label }}
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 g-3">
        <div class="col">
          <span class="me-4">{{ bienimmo.qua_nature_label }}</span>
          <MiniBI :bienimmo="bienimmo" />
        </div>
        <div class="col">
          <span class="fw-light">Copropriété :</span>
          {{ bienimmo.qua_copropriete_boolean_label
          }}<span v-if="bienimmo.qua_copropriete_boolean">
            <span class="fw-light">, charges :</span>
            {{
              bienimmo.qua_chargesannuellescopropriete
                ? bienimmo.qua_chargesannuellescopropriete + "&euro;/an"
                : "Non définie"
            }}
          </span>
        </div>
        <div class="col">
          <span class="fw-light">Carrez :</span>
          {{
            bienimmo.qua_loicarrez
              ? bienimmo.qua_loicarrez + " &#13217;"
              : "Non défini"
          }}
        </div>
        <div class="col">
          <span class="fw-light">DPE :</span>
          {{
            bienimmo.qua_valeurdudpe
              ? bienimmo.qua_valeurdudpe +
                " " +
                (bienimmo.qua_classedpeid_label || "")
              : "Non défini"
          }}
          <span class="fw-light ms-2">GES :</span>
          {{
            bienimmo.qua_valeurges
              ? bienimmo.qua_valeurges +
                " " +
                (bienimmo.qua_classeges_label || "")
              : "Non défini"
          }}
        </div>
        <div class="col" v-if="!isOccupe">
          <span class="fw-light">Ancien locataire :</span>
          {{ bienimmo.qua_nomdanstatlocatif || "Non défini" }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xl-4" v-if="!isOccupe">
          <h5 class="text-info mt-4">Chronologie</h5>
          <table>
            <tbody>
              <tr>
                <td class="text-end">
                  {{ df(bienimmo.qua_datefindelaipriorite) || "Non défini" }}
                </td>
                <td class="fw-light ps-2">Fin de délai de priorité</td>
              </tr>
              <tr>
                <td class="text-end">
                  {{ df(bienimmo.qua_datedelapublicitelegale) || "Non défini" }}
                </td>
                <td class="fw-light ps-2">Dernière publicité légale</td>
              </tr>
              <tr>
                <td class="text-end">
                  {{ df(bienimmo.qua_datedesortiedulocataire) || "Non défini" }}
                </td>
                <td class="fw-light ps-2">Sortie du locataire</td>
              </tr>
              <tr>
                <td class="text-end">
                  {{ df(bienimmo.qua_datereception) || "Non défini" }}
                </td>
                <td class="fw-light ps-2">Réception du vacant</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-6 col-xl-4" v-if="prices.length">
          <div
            class="
              d-flex
              mt-4
              mb-2
              align-items-baseline
              justify-content-between
            "
          >
            <h5 class="text-info mb-0 mt-0">Prix</h5>
            <div>
              <fa icon="calendar" class="fw-light" title="Validité" />
              {{ df(prices[0].qua_datedefindevalidite) || "Non défini" }}
            </div>
          </div>
          <table class="w-100">
            <thead>
              <tr>
                <th>Type</th>
                <th class="text-end">Montant</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="price in prices" :key="price">
                <td>{{ price.qua_typedacquereurpotentiel_label }}</td>
                <td class="text-end">{{ moneyFormat(price.qua_montant) }}</td>
              </tr>
              <tr v-if="!prices.length">
                <td colspan="2" class="text-center text-muted">Aucun</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="userIsMaster" class="text-end">
        <button
          class="btn btn-link"
          v-if="isOccupe && !etatIsVente"
          @click="openBIModal(true)"
        >
          <fa icon="walking" />
          <span class="ms-1">Déclarer vacant</span>
        </button>
        <button class="btn btn-link" v-if="!hasLocaPrinc" @click="openLPModal">
          <fa icon="user" />
          <span class="ms-1">Définir un locataire</span>
        </button>
        <button class="btn btn-link" @click="openBIModal">
          <fa icon="edit" />
          <span class="ms-1">Modifier le bien immobilier</span>
        </button>
      </div>
    </div>

    <div class="p-3 p-md-4">
      <div class="row">
        <div class="col-xl-9">
          <div v-if="hasLocaPrinc" class="card mb-3 mb-md-4">
            <div
              class="
                card-header
                text-white
                bg-primary
                d-flex
                align-items-center
              "
            >
              <h5 class="my-2">
                <span class="fw-light">Locataire Principal : </span>
                {{ locaprinc.qua_civilite_label }}
                {{ locaprinc.firstname }}
                {{ locaprinc.lastname }}
                <small class="fw-light" v-if="bail2Years">
                  depuis
                  <abbr :title="df(locaprinc.new_datedupremierbaildulocataire)"
                    >{{ bail2Years > 0 ? "plus" : "moins" }} de 2 ans</abbr
                  >
                </small>
              </h5>
              <div
                class="d-none d-xl-block ms-auto text-no-wrap fs-3"
                v-if="conclusion.qua_scoring"
              >
                <fa
                  v-for="scoring in scorings"
                  :class="[
                    'ms-1',
                    { opacity4: conclusion.qua_scoring !== scoring.id },
                  ]"
                  :icon="scoring.icon"
                  :key="scoring.id"
                  :title="scoring.label"
                />
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex flex-wrap mb-2">
                <span class="text-nowrap me-2">
                  <fa icon="at" class="fw-light" title="Email" />
                  {{ locaprinc.emailaddress1 || "Non défini" }}
                </span>
                <span class="text-nowrap me-2">
                  <fa icon="mobile" class="fw-light" title="Mobile" />
                  {{ locaprinc.mobilephone || "Non défini" }}
                </span>
                <span class="text-nowrap me-2">
                  <fa icon="phone" class="fw-light" title="Fixe" />
                  {{ locaprinc.telephone1 || "Non défini" }}
                </span>
              </div>
              <div class="row row-cols-1 row-cols-lg-2 g-2">
                <div class="col">
                  <div class="row g-2">
                    <div>
                      <span class="fw-light">Âge : </span>
                      <abbr
                        v-if="locaprinc.birthdate"
                        :title="df(locaprinc.birthdate)"
                        >{{ locaprinc.qua_age }} ans</abbr
                      ><span v-else>Non défini</span>
                    </div>
                    <div>
                      <span v-if="locaprinc.qua_contentieuxencours">
                        <span class="fw-light"
                          >Contentieux en cours, montant :
                        </span>
                        {{ locaprinc.qua_montantcontentieux }} &euro;
                      </span>
                      <span class="fw-light" v-else
                        >Aucun contentieux en cours</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row g-2">
                    <div>
                      <span class="fw-light">Situation familiale : </span>
                      {{
                        locaprinc.qua_situationfamiliale_label || "Non défini"
                      }}
                    </div>
                    <div>
                      <span class="fw-light">Personnes à charge : </span>
                      {{
                        locaprinc.qua_nombredepersonnesacharge || "Non défini"
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button
                  v-if="userIsMaster"
                  class="btn btn-link"
                  @click="openLPModal"
                >
                  <fa icon="edit" />
                  <span class="ms-1">Modifier le locataire</span>
                </button>
              </div>
            </div>
            <hr />
            <div class="card-body">
              <h5 class="mb-2 text-info">Dernier entretien</h5>
              <div
                v-if="conclusion.qua_dateentretien"
                class="row row-cols-1 row-cols-lg-2 g-2"
              >
                <div class="col">
                  <div class="row g-2">
                    <div>
                      <span class="fw-light">Date : </span>
                      <span>{{
                        df(conclusion.qua_dateentretien) || "Non défini"
                      }}</span>
                    </div>
                    <div>
                      <span class="fw-light">Niveau d'intérêt : </span>
                      <span>{{
                        conclusion.qua_niveaudinteret_label || "Non défini"
                      }}</span>
                    </div>
                    <div>
                      <span class="fw-light">Scoring : </span>
                      <span>{{
                        conclusion.qua_scoring_label || "Non défini"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row g-2">
                    <div class="fw-light">Observation Locataire :</div>
                    <div>{{ conclusion.qua_observationslocataire }}</div>
                    <div class="fw-light">Observation Quadral :</div>
                    <div>{{ conclusion.qua_observationsquadral }}</div>
                  </div>
                </div>
              </div>
              <div v-else class="text-center text-muted">Aucun</div>
            </div>
            <hr />
            <div class="card-body">
              <div class="row row-cols-1 row-cols-lg-2 g-2">
                <div class="col">
                  <h5 class="mb-2 text-info">Autres signataires du bail</h5>
                  <div class="text-center text-muted">Aucun</div>
                </div>
                <div class="col">
                  <h5 class="mb-2 text-info">Autres biens loués</h5>
                  <div class="text-center text-muted">Aucun</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dossiers.length" class="card overflow-hidden mb-3 mb-md-4">
            <div class="card-header text-white bg-primary">
              <h5 class="my-2">
                <span class="fw-light">Dossiers de vente</span>
              </h5>
            </div>
            <BienImmoDossier
              :class="[{ expanded: index === expandedDossier }]"
              v-for="(dossier, index) in dossiers"
              :key="dossier.id"
              :dossier="dossier"
              :bienimmoId="id"
              @click="expandedDossier = index"
            />
          </div>
        </div>
        <div class="col-xl-3">
          <ProgramInterlos
            :row="interlos"
            :mail-subject="'Bien immobilier: N&deg;' + bienimmo.qua_name"
            :notaire="dossierNotaire"
          />
        </div>
      </div>
    </div>
    <UpdateModal
      v-if="bienimmoToUpdate"
      :bienimmo="bienimmoToUpdate"
      :vacant="vacantMode"
      @close="closeBIModal"
      @submit="loadBI"
    />
    <LocaprincModal
      v-if="locaprincToUpdate"
      :locaprinc="locaprincToUpdate"
      :bienimmoId="id"
      @close="closeLPModal"
      @submit="loadLP"
    />
  </div>
</template>

<script src="./BienImmo.ts" />
